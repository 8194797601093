.header {
  align-items: center;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 15px 24px 15px;
  position: fixed;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  z-index: 1000;
}

.logoLink {
  display: flex;
}

.logo {
  height: 65px;
  width: 110px;
}

.colorChange {
  background-color: rgba(255, 255, 255, 1);
  color: #222;
}

@media (max-width: 640px) {
  .colorChange {
    border-bottom: 1.5px solid #34c593;
  }
  .header {
    padding: 12px 4px 12px;
  }
  .logo {
    height: 40px;
    width: 110px;
  }
}
