.items {
  display: flex;
  gap: 40px;
}

.button {
  display: none;
}
.spMenuListItemHere {
  color: red;
}

.item a {
  padding: 10px 0;
  /*線の基点とするためrelativeを指定*/
  position: relative;
}
.colorChange {
  color: #222;
}
.item a::after {
  background-color: var(--color-main);
  bottom: 0;
  content: '';
  height: 2px;
  left: 10%;
  /*絶対配置で線の位置を決める*/
  position: absolute;
  transform: scale(0, 1); /*X方向0、Y方向1*/
  transform-origin: center top; /*上部中央基点*/
  /*アニメーションの指定*/
  transition: all 0.3s;
  /*線の形状*/
  width: 80%;
}
.item.current a::after,
.item a:hover::after {
  transform: scale(1, 1); /*X方向にスケール拡大*/
}
@media (max-width: 640px) {
  .nav {
    display: none;
  }
  .colorChange {
    color: white;
  }
  .nav.open {
    background-color: var(--color-bg-painted);
    bottom: 0;
    color: var(--color-text-unpainted);
    display: block;
    left: 0;
    padding: 24px 16px;
    position: fixed;
    right: 0;
    top: 0;
  }

  .items {
    flex-direction: column;
    gap: 24px;
    margin: 40px 30px;
  }
  .item a::after {
    left: 0%;
  }

  .button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 16px;
    top: 24px;
  }

  .close {
    position: absolute;
    right: 16px;
    top: 24px;
  }
}
